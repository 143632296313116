<template>
  <div class="info-wrapper">
    <p-header></p-header>
    <div class="banner">
      <img src="~common/images/info_top.png" width="100%" alt="">
      <div class="banner-title">稿真无忧用户服务协议</div>
    </div>
    <div class="info-container">
      <div class="info-content-box">
        <div class="info-item-box">
          <!-- <p class="text" style="text-align:center;">稿真无忧用户服务协议</p> -->
          <p class="subtext">稿真无忧SaaS版平台（网址：【http://saas.gzwy.cc】，以下简称“本平台”）由北京创新一点通数码科技有限公司（以下简称“一点通”或“我们”）运营。申请账户、登录、使用本平台的企业及其授权经办人（合称“用户”或“您”）使用本平台的服务（又称“本服务”）应当阅读并遵守《稿真无忧用户服务协议》（以下简称“本协议”）。请您务必审慎阅读、充分理解各条款内容，特别是免除或限制一点通责任的相应条款（该等条款以加粗形式提示您注意）。如果用户对本协议有疑问，请通过本协议载明的方式询问，我们将解释本协议内容。如果用户不同意本协议的任何内容或者无法准确理解我们的解释，请不要申请账户或使用本平台提供的任何服务。</p>
          <p class="subtext">除非您已阅读并接受本协议所有条款，否则您无权使用本平台服务。当用户按照页面提示填写信息、阅读并同意本协议且登陆本平台，或者以任何方式实际使用本平台服务，即表示用户已接受本协议的全部内容、成为本平台用户，本协议即构成用户与一点通（合称“双方”）之间有约束力的法律文件。
          <br/>本平台的联系方式：
          <br/>电子邮件：pdm.service@greatdata.com.cn
          <br/>电 话：+86 10 6435 6368-1702</p>
        </div>
        <div class="info-item-box">
          <p class="text">一、协议的范围</p>
          <p class="subtext">1.1 本协议是您与一点通之间关于您使用本平台服务所订立的协议，适用于您在本平台的全部活动，包括但不限于您使用迅捷版、企业版及企业内部部署版服务期间开展的一切活动。版本所涵盖功能详见本产品网址：http://saas.gzwy.cc。</p>
          <p class="subtext">1.2 本协议的内容包括以下条款及一点通已经发布的或将来可能发布的各项规则（统称“规则”），所有规则为本协议不可分割的一部分，与协议正文具有同等法律效力。您确认，在您申请账户时，您已经阅读、理解并接受本协议的全部条款及各项规则，并承诺遵守法律、法规、政策的规定，如有违反而导致任何法律后果的发生，您将以自己的名义独立承担所有相应的法律责任。</p>
          <p class="subtext">1.3 一点通有权根据相关法律、法规、政策的要求及提供服务的需要不时地修改本协议或根据本协议制定、修改各类具体规则。如本平台对服务进行变更的，将在合理期限内提前在本平台以公告方式予以公布，而不再单独通知用户。请您适时注意该等变更。若您在本协议及具体规则内容公告变更后继续使用本服务的，表示您已经阅读、理解并接受修改后的协议和具体规则内容，也将遵循修改后的协议和具体规则使用本平台服务；同时就您在协议和具体规则修订前通过本平台进行的交易及其效力，视为您已同意并已按照本协议及有关规则进行了相应的授权和追认。若您不同意修改后的协议内容，您应立即停止使用本平台服务。</p>
          <p class="subtext">1.4 您通过自行或授权有关方根据本协议及本平台有关规则、说明操作确认本协议后，本协议即在您和一点通之间产生法律效力。本协议不涉及您与本平台的其他用户、消费者或其他方之间因本平台上的活动而产生的法律关系或法律纠纷。</p>
          <p class="subtext">1.5 本服务分为迅捷版、企业版、企业内部部署版。如果您希望开通平台付费服务的，您需要与我们在线下另行签署《稿真无忧平台服务合同》（“线下协议”）。线下协议与本协议共同构成用户与一点通之间关于本平台服务的约定。本协议未约定的事项，以及本协议与线下协议就同一事项的约定存在冲突的，应当以线下协议约定为准。</p>
          <p class="subtext">1.6 稿真无忧包括迅捷版、企业版、企业内部部署版。本协议仅适用于SaaS版平台用户。独立部署平台用户的相关权利义务见线下签署的《稿真无忧平台服务合同》。</p>
          <p class="subtext">1.7 本软件提供迅捷版、企业版、企业内部部署版，根据业务需要，在部分时段增设迅捷版及企业版产品免费试用期。我们为正式购买本软件的用户提供不限于免费试用期的高级特色功能。免费试用期有部分功能被锁定，用户完成购买协议后即可享用对应版本的所有功能。</p>
          <p class="subtext">1.8 使用范围
          <br/>我们可以向部分用户提供迅捷版及企业版软件的免费试用期，享有该服务的用户仅能在一定时间段内使用，并且功能也有一定限制。如果您想使用软件的所有功能，您必须付费购买企业版软件。</p>

          <p class="text">二、用户的权利</p>
          <p class="subtext">2.1 用户有权在线下协议约定的服务期内使用本平台相关服务。您购买的账号服务期届满，且未按照约定支付服务费用的，本协议终止。</p>
          <p class="subtext">2.2 用户有权根据本协议及本平台发布规则，在本平台进行稿件及其他相关文件的上传、编辑、更新、发布；有权通过本平台搜索引擎对所上传的稿件、文件等进行搜索和查询和使用。</p>
          <p class="subtext">2.3 用户有权查看来自本平台所推送的公开信息。</p>
          <p class="subtext">2.4 用户有权通过账户申请时所用邮箱进行密码找回、重新登录。</p>
          <p class="subtext">2.5 如遇上传信息（包括但不限于文件、稿件等）有被删除、被封号等情况，用户有权就相关问题向本平台进行询问。</p>
          <p class="subtext">2.6 因技术进步、国家政策变动、业务模式被认定为不符合法律规定或可能被认定为违反法律规定的，一点通无法向您继续提供原有服务或需对原有服务的服务方式、服务功能、操作方法等进行调整的，您有权在通知一点通后变更或终止本服务的部分或全部。
          <br/>无论本协议因何原因中止或终止，在协议中止或终止前的行为所导致的任何赔偿和责任，您必须完全且独立地承担责任，且非因一点通过错致本协议终止的，一点通均有权不予退还您已支付的费用。</p>
          
          <p class="text">三、服务使用规范</p>
          <p class="subtext">3.1 您仅能在本平台允许的用户数量及存储空间内（以线下协议约定的用户数量和存储空间为准）登录并使用本平台。您应当按照线下协议的约定支付服务费用。</p>
          <p class="subtext">3.2 您在此确认，您应当是在中国境内依法登记并有效存续的法人或其他组织，或者具有完全民事权利能力和完全民事行为能力的中国公民，且应当具备开展业务所需的全部资质许可、依法履行相关手续。如您不具备前述主体资格，您应当承担因此导致的一切后果，且一点通有权注销您的账户。</p>
          <p class="subtext">3.3 本平台用户账户、手机号、邮箱、密码为该用户在本平台登录的凭证，任何通过用户账号进行的行为都将被视为用户本人行为，您均应当对该等行为的结果承担责任。用户有义务保管好相关信息和所属权，不得以任何形式擅自转让或授权他人或企业使用自己在本平台的用户账户，如擅自转让或授权，一切后果由用户自负，且用户须对该账户所发生的所有活动承担责任。因用户过失导致的账户封禁等任何损失由用户自行承担，包括但不限于遗忘或泄露密码、密码被破解、登录计算机被他人侵入等。如发现任何未经授权使用您账号登录本平台或其他可能导致您账号遭窃、遗失的情况，建议您立即通知本平台。您理解本平台对您的任何请求采取行动均需要合理时间，且本平台应您请求而采取的行动可能无法避免或阻止侵害后果的形成或扩大，除本平台存在法定过错外，本平台不承担责任。</p>
          <p class="subtext">3.4 用户有义务保证在本平台所使用的信息为真实、准确、有效的信息，包括但不限于真实姓名、身份证号、营业执照信息、账户信息、联系电话、地址、邮箱等。用户有义务保证平台可通过以上联系方式与用户进行联络。同时，用户有义务在相关信息进行变更时同步更新平台用户信息。用户不得以虚假信息骗取账户名称进行账户申请，须保证账户信息不存在违法和不良信息。用户不得冒用、顶替他人/企业信息进行账户申请。用户同意授权一点通通过本协议双方以外的第三方核实用户提交的相关信息或资料。用户理解并认可，本平台仅以普通或非专业人员的知识水平标准对用户填写的信息、提交的证明和/或资质证照等进行形式查核，不代表本平台有义务或有专业能力确认用户使用服务时不会对第三方构成侵权、违约等。</p>
          <p class="subtext">3.5 用户有义务确保在本平台上发布之信息（包括但不限于文件、稿件等）真实、准确、无剽窃，不存在任何知识产权纠纷问题。如存在与其他需求方、设计及印刷服务方存在知识产权纠纷、版权归属问题，用户需自行承担责任。</p>
          <p class="subtext">3.6 用户在本平台申请账户发布信息，有义务遵守中华人民共和国各项法律、法规，不得出现以下各项情况：
          <br/>（1）违反宪法或其他各项法律法规规定的条文；
          <br/>（2）危害国家安全、泄露国家秘密、颠覆国家政权、破坏国家统一的；
          <br/>（3）损害国家荣誉和利益，损害公共利益的；
          <br/>（4）煽动民族仇恨、民族歧视、破坏民族安定团结的；
          <br/>（5）破坏国家宗教政策，宣扬邪教和封建迷信的；
          <br/>（6）散布谣言，扰乱社会秩序，破坏社会稳定的；
          <br/>（7）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；
          <br/>（8）侮辱或诽谤他人，侵害他人合法权益的；
          <br/>（9）含有法律、行政法规禁止的其他内容的。</p>
          <p class="subtext">3.7 用户保证，其向本平台上传的内容不得并禁止直接或间接的：
          <br/>（1） 删除、隐匿、改变本平台服务上显示或其中包含的任何专利、版权、商标或其他所有权声明；
          <br/>（2） 以任何方式干扰或企图干扰本平台服务或本平台网站任何部分或功能的正常运行；
          <br/>（3） 避开、尝试避开或声称能够避开任何内容保护机制或者本平台服务数据度量工具；
          <br/>（4） 未获得一点通及其关联公司、本平台事先书面同意以书面格式或图形方式使用源自一点通及其关联公司、本平台的任何注册或未注册的作品、服务标志、公司徽标(LOGO)、URL或其他标志；
          <br/>（5） 使用任何标志，包括但不限于以对此类标志的所有者的权利的玷污、削弱和损害的方式使用本平台标志，或者以违背本协议的方式为自己或向其他任何企业及个人设定或声明设定任何义务或授予任何权利或权限。
          <br/>（6） 未事先经过原始用户的同意向任何非原始用户显示或以其他方式提供任何用户信息；
          <br/>（7） 请求、收集、索取、滥用或以其他方式从任何用户那里获取对本平台帐号、密码或其他身份验证凭据的访问权；
          <br/>（8）为任何用户自动登录到本平台帐号代理身份验证凭据；
          <br/>（9）提供跟踪功能，包括但不限于识别其他用户在个人主页上查看或操作；
          <br/>（10）自动将浏览器窗口定向到其他网页；
          <br/>（11）未经授权冒充他人或获取对本平台服务的访问权；或者未经用户明确同意，让任何其他人亲自识别该用户。
          <br/>（12）用户违反上述任何一款的保证，本平台均有权就其情节，对其做出警告、屏蔽、直至取消资格的处罚，本平台有权封禁该帐号；如因用户违反上述保证而给本平台服务、本平台服务用户或本平台的任何合作伙伴造成损失，用户自行负责承担一切法律责任并赔偿损失。</p>
          <p class="subtext">3.8 用户不得为任何非法目的而使用本平台服务系统；不得以任何形式使用本平台存储网络服务侵犯本平台的商业利益；不得利用本平台服务网络服务系统进行任何可能对互联网或移动网正常运转造成不利影响的行为；</p>
          <p class="subtext">3.9 用户不得利用本平台服务从事以下活动：
          <br/>（1）未经允许，进入计算机信息网络或者使用计算机信息网络资源的；
          <br/>（2）未经允许，对计算机信息网络功能进行删除、修改或者增加的；
          <br/>（3）未经允许，对进入计算机信息网络中存储、处理或者传输的数据和应用程序进行删除、修改或者增加的；
          <br/>（4）故意制作、传播计算机病毒等破坏性程序的；
          <br/>（5）利用本平台技术漏洞或者使用第三方软件等作弊手段非法扩充本平台存储空间或影响本平台运行，以及对本平台系统运行产生威胁的；
          <br/>（6）其他危害计算机信息网络安全的行为。</p>
          <p class="subtext">3.10 如因用户利用本平台服务提供的网络服务上传、发布、传送或通过其他方式传播的内容存在权利瑕疵或侵犯了第三方的合法权益（包括但不限于专利权、商标权、著作权及著作权邻接权、肖像权、隐私权、名誉权等）而导致本平台或与本平台合作的其他单位面临任何投诉、举报、质询、索赔、诉讼；或者使本平台或者与本平台合作的其他单位因此遭受任何名誉、声誉或者财产上的损失，用户应积极地采取一切可能采取的措施，以保证本平台及与本平台合作的其他单位免受上述索赔、诉讼的影响。同时用户对本平台及与本平台合作的其他单位因此遭受的直接及间接经济损失负有全部的损害赔偿责任。因用户作弊利用本平台技术漏洞或者使用第三方软件等作弊手段非法扩充本平台存储空间或影响本平台运行，以及对本平台系统运行产生威胁的，本平台有权采取任何措施包括但不限于封禁账户、删除文件等方式，保护本平台权益。</p>
          <p class="subtext">3.11 用户在遵守法律及本协议的前提下可依据本协议使用本平台，不得以自身名义实施或者允许其他人实施下列行为：
          <br/>（1）对本平台进行修改、散布或者根据本平台编写衍生作品，或者以分许可、借用、出租等形式将平台账号给与他人使用；
          <br/>（2）不得删除本平台及其他副本上所有关于版权的信息、内容；
          <br/>（3） 实施反向工程、反编译、解码、解密、反汇编或者采用其他任何方式从本软件中提取源代码；
          <br/>（4）对于本平台的功能、模板等相关信息，未经我们书面同意，用户不得将其包含于其它软件、光盘或者衍生产品等。
          <br/>（5） 移除或者修改软件中所包含的本平台或者其他第三方的版权标识、商标标识；
          <br/>（6）以任何非法形式或者本协议未明确授权的方式使用本平台；
          <br/>（7）在本平台中使用非本平台提供的任何知识产权素材或资源（例如设计稿件、设计素材等），除非您已经获得权利人的合法授权；
          <br/>（8）用户不得利用本平台发表、传播、储存侵害他人知识产权、商业秘密权等合法权利内容。
          <br/>（9）用户不得利用本平台发表、传播、储存违反国家法律、危害国家安全、祖国统一、社会稳定的内容，或侮辱、诽谤、色情、暴力等任何违反国家法律法规政策的内容。</p>
          <p class="subtext">3.12 账户使用规范
          <br/>（1）您必须创建一个帐户以使用本平台。
          <br/>（2）如您通过申请账户登录本平台，则您应同意遵守本协议和隐私政策的全部条款，并根据本协议的规定完成全部申请程序申请本平台账户。
          <br/>（3）您在使用第三方账户登陆或申请账户时（如邮件、钉钉等），需遵守第三方相关规定。
          <br/>（4）本平台账户由您自行保管，若因为您自身原因，而导致您的账户、密码遭他人非法使用时，本平台不承担任何责任，并且如果您的账户遭受他人攻击或诈骗等行为导致的损失及后果，均由您自行承担，您对您使用的账户下的所有行为结果均自行负责。</p>
          <p class="subtext">3.13 您应保证帐号名称及相关信息不存在下列情形：
          <br/>（1）违反宪法或法律法规规定的；
          <br/>（2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；
          <br/>（3）损害国家荣誉和利益的，损害公共利益的；
          <br/>（4）煽动民族仇恨、民族歧视，破坏民族团结的；
          <br/>（5）破坏国家宗教政策，宣扬邪教和封建迷信的；
          <br/>（6）散布谣言，扰乱社会秩序，破坏社会稳定的；
          <br/>（7）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；
          <br/>（8）侮辱或者诽谤他人，侵害他人合法权益的；
          <br/>（9）含有法律、行政法规禁止的其他内容的。
          <br/>（10）您应确保您的帐号名称不得为您冒用关联机构或社会名人申请的账户名称。</p>
          <p class="subtext">3.14 用户应保证申请账户时提供的资料真实无误。</p>
          <p class="subtext">3.15 使用本平台必须遵守国家有关法律和政策，维护国家利益，保护国家安全。</p>
          <p class="subtext">3.16 用户利用本平台发生的商业行为均由用户自行负责，因利用本平台进行商业行为所产生的一切纠纷均与我们无关。如因此给一点通或其关联方造成损失的，用户应当赔偿一点通或其关联方的一切损失。同时，一点通有权视情况终止本平台的全部或部分服务。</p>
          <p class="subtext">3.17 SaaS平台用户可在服务期内获得并使用最新的软件；且通过指定的方式获得指定范围内的技术支持。用户享有反映和提出意见的权利，相关意见将被作为首要考虑，但没有一定被采纳的承诺或保证。</p>
          <p class="subtext">3.18 我们不对因平台使用错误等问题所引起的用户损失而承担任何责任，但我们将尽量避免此类情况的发生且对付费用户在出现此类问题的情况下提供必要的支持服务。</p>
          <p class="subtext">3.19 我们保留在任何时候为您提供本平台的修改、升级版本的权利，以及为修改或升级服务收取费用的权利。</p>
          <p class="subtext">3.20 云存储
          <br/>（1）本平台仅允许同步平台内文件。您应同意不会将其他格式的文件伪装成本平台软件文件同步到云端。
          <br/>（2）您的个人文件、团队文件被存储于云端。由您及团队上传并存储的文件，您将保留所有内容的知识产权。除非您给予许可，我们不得复制、分享或传送您的文件给任何第三方。
          <br/>（3）请确保您分享、发布或保存到本平台云存储的内容是完全合法的，包括但不限于稿件、文件等。如您分享、发布或保存非法的内容导致的损失及后果，均由您承担，您对您提交的内容负责。本平台具有单方面删除账户文件、取消访问和编辑权限或采取其他行动的权限。
          <br/>（4）禁止多人共享一个帐户，请再次确认您不会向他人泄露或分享您的帐户，否则可能导致文件丢失或文件更改。</p>

          <p class="text">四、平台的权利与义务</p>
          <p class="subtext">4.1 权利</p>
          <p class="subtext">4.1.1若用户出现违反相关国家法律、法规而造成不良社会影响，平台有权将其文件，文稿等信息作为证据依法提交司法机关。</p>
          <p class="subtext">4.1.2未经我们书面(纸质文件、邮件等)同意，您不得向任何第三方分发、转让、出租、发放本平台账号。</p>
          <p class="subtext">4.1.3在本协议有效期内，一点通可以不时向用户提供软件更新和/或内容更新。“更新”是指许可软件的新版本，其中包含技术修改，更新信息，更改的功能或一点通旨在改进或添加，删除或以其他方式修改许可的任何方面的任何其他更改。如果许可软件是对先前版本的更新，则用户必须拥有先前版本的有效许可。一点通向用户提供的任何更新均以许可交换为基础，以便用户同意作为接收更新的先决条件，一点通将终止用户使用任何先前版本的许可软件的所有权利。一旦更新版本发布，一点通可以停止对先前版本的服务或支持，而不向用户发出任何通知。</p>
          <p class="subtext">4.1.4一点通在此没有义务为用户提供与软件相关的任何业务部署及技术支持服务。然而，在服务期间，若客户需要提供额外附加服务，一点通有权向用户收取额外附加服务费用。</p>
          <p class="subtext">4.1.5如用户违反本协议约定，我们有权根据相关法律法规要求随时暂停用户使用本平台，并通知用户进行改正。如用户收到前述通知后3日内未改正的，我们有权立即停止平台服务，且有权视情况通知用户后解除本协议并不承担任何责任。用户由此给一点通造成损失的，用户负全部赔偿责任。本协议的终止不影响任何一方在协议终止前已经取得的任何权利、义务或责任，或者协议终止后继续有效的权利、义务、责任。</p>
          <p class="subtext">4.1.6如用户订购的服务期届满未续订的或本协议因任何原因提前解除、终止或撤销的，自服务期届满或自本协议解除、终止或撤销之日起的365个自然日后，本平台将自动删除您在本平台上的所有使用痕迹。</p>
          <p class="subtext">4.1.7用户将赔偿一点通并确保一点通免遭任何由于用户非法或者不当使用本平台而导致的第三方的索赔，并同意承担一点通因此遭受的损失、责任、赔偿、罚款、成本 、费用。本条款在协议终止之后仍然有效。</p>
          <p class="subtext">4.1.8在本协议期限内，用户授予一点通在宣传材料中载明用户是一点通的客户的权利。用户可以通过电子邮件向pdm.service@greatdata.com.cn提交书面请求，并要求将其排除在软件宣传材料之外。</p>
          <p class="subtext">4.2 义务</p>
          <p class="subtext">4.2.1一点通按照本协议及线下协议约定的条件和条款，向您提供本平台服务。具体服务内容以本平台展示并经您申请且由本平台实际其提供的为准。</p>
          <p class="subtext">4.2.2平台有义务在现有技术水平基础上努力确保网络交流平台的正常运行，尽力避免服务终端或将中断时间限制在最短时间内，以保证用户流畅使用平台服务。</p>
          <p class="subtext">4.2.3本平台有义务对用户在申请账户、使用网站信息平台中所遇到的问题及所反映的情况及时作出回复。</p>

          <p class="text">五、知识产权</p>
          <p class="subtext">5.1 本平台相关的所有软件、程序、内容，包括但不限于网站构架、功能、模板等，均由本平台或其他权利人依法享有相应的知识产权，包括但不限于著作权、商标权、专利权或其他专属权利等，受到相关法律保护。未经本平台或权利人授权，用户不得修改、出借、出租、散播本平台及所使用的上述任何资料和资源，或根据以上资料和资源制作成任何种类的产品。</p>
          <p class="subtext">5.2 本平台授予企业用户不可转移及非专属的使用权，使企业用户可以通过计算机、移动端使用本平台的目标代码（以下简称“软件”），但用户不得且不得允许任何第三方复制、修改、创作衍生作品、进行还原工程、反向组译、或以任何其它方式破译或试图破译源代码，或出售、转让“软件”或对“软件”进行再授权，或以其它方式转移“软件”之任何权利。用户同意不以任何方式修改“软件”，或使用修改后的“软件”。</p>
          <p class="subtext">5.3 本平台秉承尊重知识产权的理念，在用户允许的情况下展示、呈现、传播用户所提交的各项内容。</p>
          <p class="subtext">5.4 凡用户在本平台上传相关信息，均视为用户对其所上传之内容拥有相关权利，包括但不限于文件、稿件等各类内容。本平台不对用户所上传以上各类内容承担任何法律责任，若存在侵犯任何第三人著作权等合法权益的内容，本平台保留第一时间确认权益所属情况并对该内容保有自行处理的权利。</p>
          <p class="subtext">5.5 未经本平台书面许可，对于本平台所呈现的内容，任何机构或个人均不得擅自复制、链接、非法使用、转载、爬取，不得以任何方式建立镜像站点，一经查实，本平台将依法采取追究侵权者法律责任。</p>
          <p class="subtext">5.6 本平台是一点通或其他授予一点通许可的第三方的知识产权。本平台的结构，组织和代码是一点通和此类第三方的宝贵商业秘密和机密信息。本平台受中华人民共和国法律保护，包括但不限于中华人民共和国著作权法。除非本协议明确规定，否则用户不会被授予对许可软件的任何知识产权。未经一点通事先明确书面同意，用户不得制作或发布任何有关本平台或一点通的公开声明。</p>
          <p class="subtext">5.7 本平台是由我们独立开发。平台的一切版权等知识产权，以及与软件相关的所有信息内容，包括但不限于：文字表述及其组合、商标、图标图饰、界面设计、版面框架、有关数据、印刷材料、电子文档等均受著作权法和国际著作权条约以及其他知识产权法律法规的保护。除了任何我们授权许可协议所提供的明确书面许可外，拥有本平台并不赋予您任何有关这些专利、商标、版权或其它知识产权的许可。未经我们授权，任何拷贝、销售、转让、出租、修改本平台的行为均被认为是侵权行为。</p>
          
          <p class="text">六、免责条款</p>
          <p class="subtext">6.1 所有本平台所提供的信息，不可用于其他任何商业用途。本平台不保证用户的准确性、有效性、及时性或完整性。在法律允许的范围内，本平台不承担用户或任何人士就使用或未能使用本平台所提供的信息或任何链接或项目所引致的任何直接、间接、附带、从属、特殊、惩罚性的损害赔偿（包括但不限于收益、预期利润的损失或失去的业务、未实现预期的节减）。本平台保留自行决定更正本平台中任何部分的任何错误或遗漏的权利。本平台可以在任何时间对平台及平台上呈现的内容（产品、功能、服务、价格）等作出任何其他修改而无需通知。</p>
          <p class="subtext">6.2 本平台上的信息和内容以及在本平台上可获得的软件和材料均“依原样”提供而不包含明示或暗示的任何性质的陈述或保证。在法律许可的最大限度内，平台声明不作出任何明示或暗示的陈述和保证，包括但不限于有关安全性、准确性、完整性、适销性、无侵权、适合任何特别目的或没有计算机病毒的保证，您应为使用本平台承担全部责任和风险。</p>
          <p class="subtext">6.3 如发生以下情况，本平台不对用户的直接或间接损失承担法律责任：营业中断；无法登录平台，或登录平台延迟或中断；数据无法传送、错误传送、毁损、灭失或其他修改；因处理平台上对其它网站的链接或平台上存在对其它网站的链接所遭受的任何种类的损失或损害；就客户使用本平台（包括链接到第三方网站或自第三方网站链接）而可能产生的计算机病毒、系统失灵或功能紊乱；因不可抗力或本平台无法合理控制的其他原因所导致的用户损失。</p>
          <p class="subtext">6.4 鉴于网络服务的特殊性，您同意一点通会变更、中断部分或全部的网络服务并按本协议规定删除您在使用服务中提交的任何资料，而无需另行通知您，也无需承担任何责任。如因系统维护或升级的需要而需要暂停网络服务，本平台将尽可能事先进行通告。如发生下列情形之一，本平台有权随时中断或终止向用户提供网络服务而无需通知用户，并拒绝用户于现在和未来使用本平台所提供服务之全部或任何部分：
          <br/>（1）用户提供任何错误、不实、过时、不完整或具有误导性的资料，或者本平台有理由怀疑前述资料为错误、不实、过时、不完整或具误导性的；
          <br/>（2）用户违反本协议或相关使用协议中规定的使用规则。
          <br/>除以上所述情形外，本平台同时保留在不事先通知用户的情况下随时中断或终止部分或全部网络服务的权利，对于所有服务的中断或终止而造成的任何损失，除法律另有规定或双方另有约定外，本平台无需对用户或任何第三方承担任何责任。</p>
          <p class="subtext">6.5 在任何情况下，一点通均不对任何间接性、后果性、惩戒性、偶然性、特殊性或刑罚性的损害，包括用户使用服务而遭受的名誉损失、利润损失、派生的损失等承担责任。</p>
          <p class="subtext">6.6 在任何情况下，一点通对用户承担赔偿责任的金额，不超过一点通从用户已实际收取的总费用金额。</p>
          
          <p class="text">七、数据与隐私</p>
          <p class="subtext">7.1 一点通对本平台的数据依法拥有相应的权利。前述数据包括用户信息、用户列表、用户关系、用户使用行为等。上述数据如涉及用户信息的，一点通将依法保护。未经一点通事先书面同意，任何人不得为任何目的擅自保存、备份、泄露、使用或授权他人使用前述数据。</p>
          <p class="subtext">7.2  一点通非常重视用户信息和隐私保护，一点通通过本平台向用户提供服务时，将按照相关法律法规的规定收集、存储、使用、共享、转移、公开披露、保护和管理用户信息和隐私。本平台的隐私政策详见《稿真无忧隐私政策》，用户同意仔细阅读并充分理解和接受该隐私政策，并同意该隐私政策作为本协议的重要组成部分。</p>
          <p class="subtext">7.3 用户使用本平台及本平台提供的产品和服务过程中保证遵守如下要求：
          <br/>（1）数据来源合法、不侵犯他人合法权益，必要时用户应当获得有关的许可或批准，并符合不时发布、更新的法律法规的要求。
          <br/>（2）不攻击或不通过任何不正当手段窃取、获取营销云平台中的数据。
          <br/>（3）如司法机关、行政机关或第三方权利人提出用户的数据、所发布信息等涉嫌违法、侵权等，通知本平台要求删除的，一点通有权按照司法机关、行政机关或第三方权利人的要求进行删除，且无需承担任何违约责任。同时，一点通有权按照本协议及相关规则要求终止相应服务。</p>
          <p class="subtext">7.4 用户理解并同意，数据备份和保存是用户的义务和责任，一点通不对用户数据备份和保存工作或结果承担任何责任，双方另有约定的除外。</p>
          <p class="subtext">7.5 用户对平台中的业务数据进行删除、更改等操作时，应当谨慎操作。如用户删除平台中的用户业务数据的，该等数据将被永久删除，不能恢复。</p>
          
          <p class="text">八、法律声明</p>
          <p class="subtext">8.1 本平台保留在任何时间自行修改、增删本法律声明中任何内容的权利。您每次登陆或使用本平台时均视为同意受当时有效的条款的制约。因此，您每次登陆或使用本平台时应查看本法律声明的日期，并检查与上一版本的修改之处。</p>
          <p class="subtext">8.2 本法律声明的制定、执行和解释及争议的解决均应适用中华人民共和国法律。用户与一点通因使用本平台所产生的争议，协商无效的，任何一方可以向北京市朝阳区人民法院提起诉讼。</p>
          <p class="subtext">8.3 如果用户因任何原因需要联系我们，请联系（我们的邮件地址）。我们的经营地址位于：北京市朝阳区酒仙桥路14号50号楼A1区4门2层127室。</p>
          <p class="subtext">8.4 我们可能会不时更新本协议，如果您继续使用软件则视为您接受此类更新。如果您不能接受更新的协议，您可以选择终止本协议。在这种情况下，我们对您不承担任何责任或费用。</p>
          <p class="subtext">8.5 您同意使用本平台，即意味着您承认已经阅读并理解了上述內容，且同意遵守其中的条款和条件。</p>
          <p class="subtext">8.6 您同意一点通因经营业务需要有权将本协议项下的权利义务就部分或全部进行转让给其他方，而无须再通知予您并取得您的同意。</p>
          <p class="subtext">8.7 倘若本协议任何条款被裁定为无效或不可强制执行，该项条款应被撤销，而其余条款应予遵守和执行。条款标题仅为方便参阅而设，并不以任何方式界定、限制、解释或描述该条款的范围或限度。一点通未就您或其他人士的某项违约行为采取行动，并不表明一点通撤回就任何继后或类似的违约事件采取动的权利。</p>

        </div>
        <!-- <div class="info-bottom-box">平台运营委托：北京创新一点通数码科技有限公司</div> -->
      </div>
    </div>
    <p-footer></p-footer>
  </div>
</template>

<script type="text/ecmascript-6">
  import PHeader from 'components/header/header'
  import PFooter from 'components/footer/footer'

  export default {
    data() {
      return {}
    },
    /* eslint-disable no-undef */
    created() {
    },
    methods: {},
    watch: {},
    components: {
      PHeader,
      PFooter
    }
  }
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
  .info-wrapper
    width 100%
    min-width 1270px
    .banner
      padding-top: 0.35rem;
      position relative
      text-align: center
      width: 100%
      .banner-title
        position absolute
        top 1.1rem
        left 51%
        transform: translateX(-50%)
        font-size: 0.23rem;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 0.44rem;
    .info-container
      width 100%
      box-sizing border-box
      .info-content-box
        background: #FFFFFF;
        .info-bottom-box
          font-size: 0.08rem;
          font-weight: 400;
          color: #666666;
          text-align: center
          margin-top: 0.8rem
          margin-bottom: 0.2rem
        .info-item-box
          color #333
          text-align left
          box-sizing border-box
          padding .45rem 2.29rem .21rem 2.29rem
          .text
            font-size: 0.23rem;
            font-weight: bold;
            color: #1A1A1A;
            letter-spacing: 1px;
            margin-top: 0.3rem;
          .subtext
            font-size: 0.12rem;
            font-weight: 400;
            color: #666666;
            line-height: 0.24rem
            margin-top: 0.2rem
            letter-spacing: 1px;
            text-indent: 2em
        .info-contact-box
          padding-bottom 0.3rem
          text-align center
          color #333
</style>
